<template>
  <div style="background-color: #f7f7f7">
    <div class="title_div">
      <div
        style="
          height: 6vh;
          line-height: 12vh;
          font-size: 4.6vw;
          display: flex;
          justify-content: center;
        "
      >
        <div>殡仪馆管理系统</div>
        <!-- <div v-else>{{ pagetype == 1 ? "总部看板2222222222" : "门店看板555555555" }}</div> -->
      </div>
      <div
        style="
          display: flex;
          justify-content: flex-end;
          font-size: 3.5vw;
          margin-right: 3vw;
        "
      >
        <div>{{ new_show_date }}</div>
      </div>
    </div>
    <!--列表1 dashed-->
    <div style="display: flex; margin-top: 0.5vh; justify-content: center">
      <div
        style="
          display: flex;
          height: 12vh;
          width: 93%;
          align-items: center;

          justify-content: space-between;
        "
      >
        <div
          v-for="(item, index) in table_data1"
          :key="index"
          class="every_option1"
        >
          <img
            :src="item ? item.img : ''"
            style="
              height: 7vw;
              width: 7vw;
              position: absolute;
              right: 3vw;
              bottom: 0.8vw;
            "
          />
          <div
            style="
              font-size: 3.8vw;
              margin-top: 0.6vh;
              position: absolute;
              left: 3vw;
              top: 0.8vw;
            "
          >
            {{ item ? item.name : "" }}
          </div>
          <div
            style="
              font-size: 4vw;
              margin-top: 0.6vh;
              color: #898989;
              position: absolute;
              left: 3vw;
              top: 6.5vw;
            "
          >
            {{ item ? item.value : "" }}
          </div>
        </div>
      </div>
    </div>

    <!--时间-->
    <div style="display: flex; justify-content: center; margin-top: 0.1vh">
      <div
        style="
          display: flex;
          height: 6vh;
          align-items: center;
          background-color: #ffffff;
          border-radius: 7px;
        "
      >
        <div
          v-for="(item, index) in time_item"
          :key="index"
          class="every_time"
          @click="chooseTime(index)"
          :style="
            index < 3 ? 'border-right: 1px solid rgba(204, 204, 204, 0.3);' : ''
          "
        >
          <img
            v-if="time_value != index"
            :src="item.icon"
            style="height: 5.2vw; width: 5.2vw"
          />
          <img
            v-else
            :src="item.icon_xuan"
            style="height: 5.2vw; width: 5.2vw"
          />
          <div
            class="time_choose"
            :style="time_value == index ? 'color:#2163E5;' : ''"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
    <!--销售汇总区-->
    <div
      style="
        display: flex;
        margin-top: 1.6vh;
        align-items: center;
        margin-left: 5vw;
        margin-bottom: 1vh;
      "
    >
      <img src="@/assets/icon/xiaoshouhuizong.png" class="option_img" />
      <div class="option_title">销售汇总区</div>
    </div>
    <!--列表2-->
    <div
      style="
        display: flex;
        flex-wrap: wrap;
        margin-left: 4vw;
        justify-content: space-around;
        width: 93%;
      "
    >
      <div
        v-for="(item, index) in table_data2"
        :key="index"
        class="every_option2"
      >
        <div
          style="
            font-weight: 800;
            font-size: 3.6vw;
            margin-left: 1vw;
            margin-top: 1vh;
            text-align: center;
            position: absolute;
            left: 3vw;
            top: 0.8vw;
          "
        >
          {{ item ? item.name : "" }}
        </div>
        <div
          style="
            display: flex;
            display: flex;
            justify-content: space-between;
            margin-top: 0.4vh;
            margin: 1vh 0;
          "
        >
          <div
            class="option2_font2"
            style="position: absolute; left: 3vw; top: 8.5vw; font-size: 3.6vw"
          >
            {{ item ? item.value : "" }}
          </div>
          <img
            :src="item ? item.img : ''"
            style="
              height: 7vw;
              width: 7vw;
              margin-right: 2vw;
              position: absolute;
              right: 3vw;
              bottom: 1vw;
            "
          />
        </div>
      </div>
    </div>
   
    <!-- 数据对比 -->
    <div
      style="
        display: flex;
        margin-top: 1.6vh;
        align-items: center;
        margin-left: 5vw;
        margin-bottom: 1vh;w
      "
    >
      <img src="@/assets/icon/xiaoshouhuizong.png" class="option_img" />
      <div class="option_title">数据对比</div>
    </div>
    <div>
      <table
        border="1"
        cellspacing="0"
        style="width: 93%; border-radius: 9px 9px 0 0;border-color:#DCDCDC;"
      >
        <thead>
          <tr>
            <th style="border-radius: 9px 0 0 0;background-color:#DCDCDC ;"></th>
            <th colspan="2" style="background-color:#DCDCDC ;">单班</th>
            <th colspan="2" style="border-radius: 0 9px 0 0;background-color:#DCDCDC ;">双班</th>
          </tr>
          <tr>
            <th >
            类别
            </th>
            <th>班长</th>
            <th>车队</th>
            <th>班长</th>
            <th>车队</th>
          </tr>
        
          <tr v-for="(item, index) in esampleLisdt" :key="index">
            <th>{{ item.typeName }}</th>
            <th>{{ item.singleNonFleetFee }}</th>
            <th>{{ item.singleFleetFee }}</th>
            <th>{{ item.doubleNonFleetFee}}</th>
            <th>{{ item.doubleFleetFee }}</th>
          </tr>
        </thead>
      </table>
      <!-- <table border="1" cellspacing="0" width="93%" height="150">
            <caption>纵向合并单元格</caption>
            <tr>
              <th>班级</th> 
              <th colspan="2">姓名</th>
              <th>电话</th> 
            </tr>
            <tr>
            <td rowspan="2">601班</td> 
            <td>Jack</td> 
            <td>24</td> 
            <td>1351234567</td> 
           </tr>
            <tr>
           <td>Tom</td> 
           <td>22</td> 
           <td>1351234567</td>  
          </tr>

            <tr>
            <td  rowspan="3">602班</td>
            <td>Rose</td> <td>22</td> 
            <td>1351234567</td>  
           </tr>
            <tr> 
            <td>张三</td> 
            <td>25</td> 
            <td>1351234567</td> 
           </tr>
            <tr>
            <td>李四</td>
            <td>25</td> 
            <td>1351234567</td>
          </tr>
        </table> -->
    </div>
     <!--服务中心收费登记-->
     <div
      style="
        display: flex;
        margin-top: 1.6vh;
        align-items: center;
        margin-left: 5vw;
        margin-bottom: 1vh;w
      "
    >
      <img src="@/assets/icon/xiaoshouhuizong.png" class="option_img" />
      <div class="option_title">收费登记</div>
    </div>
    <div
      style="display: flex; flex-wrap: wrap; margin-left: 4vw"
      class="every_optionsF"
    >
      <!-- <div v-for="(item,index) in shList" :key="index"> -->
      <table style=" font-size:3.9vw">
        <thead>
          <tr>
            <th style="padding-left:6px">商户</th>
            <th style="padding-left: 6px">入殡时间</th>
            <th style="padding-left: 6px">值班收费</th>
            <th style="padding-left: 6px">车队收费</th>
            <th style="padding-left: 6px">合计</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in binYList" :key="index">
            <td style="padding-left:6px">{{ item.商户 }}</td>
            <td style="padding-left:6px">{{ item.入殡时间}}</td>
            <td 
              style="color: #2163e5; font-weight: 600;padding-left:6px"
              @click="table_on(item)"
            >
              [{{ item.值班收费 }}]
            </td>
            <td style="color: #2163e5; font-weight: 600;padding-left:6px" @click="car_on(item)">
              [{{ item.车队收费 }}]
            </td>
            <td style="padding-left:6px">{{ item.合计}}</td>
          </tr>
        </tbody>
      </table>
      <!-- </div> -->
    </div>
    <!-- <el-table :data="tableData3" style="width: 100%">
      <el-table-column width="120" prop="date"></el-table-column>
      <el-table-column prop="name" label="姓名" width="120"></el-table-column>
      <el-table-column label="地址">
        <el-table-column
          prop="province"
          label="省份"
          width="120"
        ></el-table-column>
        <el-table-column prop="city" label="市区" width="120"></el-table-column>
        <el-table-column
          prop="address"
          label="地址"
          width="300"
        ></el-table-column>
        <el-table-column prop="zip" label="邮编" width="120"></el-table-column>
      </el-table-column>
    </el-table> -->
    <!--列表2-->

    <!--销售汇总区-->
    <!-- <div
      style="
        display: flex;
        margin: 1.6vh;
        align-items: center;
        margin-left: 5vw;
      "
    >
      <img src="@/assets/icon/xiaoshoue.png" class="option_img" />
      <div class="option_title">
        {{ pagetype == 2 ? "营业员" : "" }}销售额示意图
      </div>
    </div> -->
    <!--图表-->
    <!-- <div style="display: flex; justify-content: center"> -->
    <!-- 总店图 -->
    <!-- <div
        v-if="pagetype == 1"
        class="line_body"
        :style="'width:' + (200 + line_data.length * 22) + 'px;'"
      >
        <LineChart id="zongbu" :table_data="line_data"></LineChart>
      </div> -->
    <!-- 门店图 -->
    <!-- <div
        v-if="pagetype == 2"
        class="line_body"
        :style="'width:' + (200 + line_data.length * 22) + 'px;'"
      >
        <LineChart id="mendian" :table_data="line_data"></LineChart>
      </div> -->
    <!-- 区域图(一天) -->
    <!-- <div v-if="pagetype==2&&user_type2&&(time_value==0||time_value==1)" class="line_body" style="width:360vw;">
        <LineChart id="360" :table_data="line_data"></LineChart>
      </div> -->
    <!-- 区域图(一周以上) -->
    <!-- <div v-if="pagetype==2&&user_type2&&(time_value==2||time_value==3)" class="line_body" style="width:360vw;">
        <LineChart id="360" :table_data="line_data"></LineChart>
      </div> -->
    <!-- </div> -->
    <!--门店排名-->
    <!-- <div style="display: flex; justify-content: center; margin-top: 2vh;">
      <div style="background-color: #ffffff; width: 92vw;">
        <div
          style="
            display: flex;
            align-items: center;
            margin-left: 2vw;
            height: 6vh;
          "
        >
          <img src="@/assets/icon/mendianpaiming.png" class="option_img" />
          <div class="option_title">
            {{ pagetype == 1 ? "门店排名" : "营业员销售排名" }}
          </div>
        </div>
        <div style="margin-left: 2vw; overflow: auto">
          <div
            style="
             width:125vw;
              display: flex;
              color: #9c9c9c;
              font-size: 3.2vw;
              margin-bottom: 0.5vh;
              overflow:scroll;
            "
          >
            <div style="min-width: 10vw; text-align: center">排名</div>
          
            <div v-if="pagetype == 1" style="width:40vw; text-align:center">
              主体
            </div>
            <div v-if="pagetype == 1" style="width:75vw; text-align:center">
              门店
            </div>
            
            <div v-if="pagetype == 2" style="width: 40vw; text-align: center">
              营业员
            </div>
            
            <div style="width: 50vw; text-align:center">销售额</div>
            <div style="width: 50vw; text-align:center">毛利额</div>
            <div style="width: 50vw; text-align: center">毛利率</div>
            <div style="width: 50vw; text-align:center">毛利3</div>
            <div style="width: 50vw; text-align: center">毛利率3</div>
            
            
            <div v-if="pagetype == 2" style="width: 40vw; text-align: center">
              会员销售额
            </div>
            <div v-if="pagetype == 2" style="width: 30vw; text-align: center">
              客单价
            </div>
            <div v-if="pagetype == 2" style="width: 30vw; text-align: center">
              客流量
            </div>
          </div>

          <div
            v-for="(item, index) in table_data3"
            :key="index"
            style="
            width:125vw;
              display: flex;
              color: #9c9c9c;
              font-size: 3vw;
              border-top: 1px solid #e2e2e2;
overflow:scroll;
            "
          >
            <div
              v-if="index < 3"
              style="
                min-width: 10vw;
                border-left: 1px solid #e2e2e2;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <img
                v-if="index == 0"
                src="@/assets/icon/paiming1.png"
                class="ranking_icon"
              />
              <img
                v-if="index == 1"
                src="@/assets/icon/paiming2.png"
                class="ranking_icon"
              />
              <img
                v-if="index == 2"
                src="@/assets/icon/paiming3.png"
                class="ranking_icon"
              />
            </div>
            <div
              v-if="index > 2 && index < table_data3.length - 1"
              style="
                min-width: 10vw;
                text-align: center;
                border-left: 1px solid #e2e2e2;
                min-height: 3vh;
                line-height: 3vh;
              "
            >
              {{ index + 1 }}
            </div>
               <div
              v-if="pagetype == 1"
              style="
                width:40vw;
                text-align: center;
                border-left: 1px solid #e2e2e2;
                min-height: 3vh;
                line-height: 3vh;
              "
            >
              {{ item.主体 }}
            </div>
            <div
              v-if="index == table_data3.length - 1"
              style="
                min-width: 10vw;
                text-align: center;
                border-left: 1px solid #e2e2e2;
                min-height: 3vh;
                line-height: 3vh;
              "
            ></div>
           
            <el-tooltip :content="item.分支机构编码" placement="top">
              <div
              v-if="pagetype == 1"
              style="
             
                width: 75vw;
                text-align: center;
                border-left: 1px solid #e2e2e2;
                min-height: 3vh;
                line-height: 3vh;
                overflow: hidden; white-space: nowrap;
              "
            >
              {{ item.分支机构编码 }}
            </div>
</el-tooltip>
            <div
              v-if="pagetype == 2"
              style="
                width: 25vw;
                text-align: center;
                border-left: 1px solid #e2e2e2;
                min-height: 3vh;
                line-height: 3vh;
              "
            >
              {{ item.营业员 }}
            </div>
            <div
              style="
                width: 50vw;
                text-align: center;
                border-left: 1px solid #e2e2e2;
                min-height: 3vh;
                line-height: 3vh;
              "
            >
              {{ item.销售额 ? item.销售额 : "" }}
            </div>
            <div
              style="
                width: 50vw;
                text-align: center;
                border-left: 1px solid #e2e2e2;
                min-height: 3vh;
                line-height: 3vh;
              "
            >
              {{ item.毛利额? item.毛利额 : "" }}
            </div>
            <div
              style="
                width: 50vw;
                text-align: center;
                border-left: 1px solid #e2e2e2;
                min-height: 3vh;
                line-height: 3vh;
              "
            >
              {{ item.毛利率? item.毛利率 : "" }}
            </div>
            <div
              style="
                width: 50vw;
                text-align: center;
                border-left: 1px solid #e2e2e2;
                min-height: 3vh;
                line-height: 3vh;
              "
            >
              {{ item.gk毛利? item.gk毛利 : "" }}
            </div>
            <div
              style="
                width: 50vw;
                text-align: center;
                border-left: 1px solid #e2e2e2;
                min-height: 3vh;
                line-height: 3vh;
              "
            >
              {{ item.gk毛利率? item.gk毛利率 : "" }}
            </div>
          
            <div
              v-if="pagetype == 2"
              style="
                width: 40vw
                text-align: center;
                border-left: 1px solid #e2e2e2;
                min-height: 3vh;
                line-height: 3vh;
              "
            >
              {{ item.会员销售额 }}
            </div>
            <div
              v-if="pagetype == 2"
              style="
                width: 30vw;
                text-align: center;
                border-left: 1px solid #e2e2e2;
                min-height: 3vh;
                line-height: 3vh;
              "
            >
              {{ item.客单价 }}
            </div>
            <div
              v-if="pagetype == 2"
              style="
                width: 30vw;
                text-align: center;
                border-left: 1px solid #e2e2e2;
                min-height: 3vh;
                line-height: 3vh;
              "
            >
              {{ item.客流量 }}
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!--电商排名-->
    <!-- <div
      v-if="pagetype == 1"
      style="display: flex; justify-content: center; margin-top: 3vh"
    >
      <div style="background-color: #ffffff; width: 92vw" v-if="eight != 8">
        <div
          style="
            display: flex;
            align-items: center;
            margin-left: 2vw;
            height: 6vh;
          "
        >
          <img src="@/assets/icon/mendianpaiming.png" class="option_img" />
          <div class="option_title">电商排名</div>
        </div>
        <div style="margin-left: 2vw; overflow: scroll">
          <div
            style="
              display: flex;
              color: #9c9c9c;
              font-size: 3.2vw;
              margin-bottom: 0.5vh;
            "
          >
            <div style="min-width:8vw; text-align: left">排名</div>
            <div style="width: 35vw; text-align: left;">主体名称</div>
            <div style="width: 40vw; text-align: left">店铺名称</div>
            <div style="width: 30vw; text-align:left">销售额</div>
            <div style="width: 30vw; text-align: left">成本额</div>
            <div style="width: 30vw; text-align:left">毛利额</div>
            <div style="width: 30vw; text-align:left">毛利额3</div>
          </div>

          <div
            v-for="(item, index) in table_data4"
            :key="index"
            style="
              display: flex;
              color: #9c9c9c;
              font-size: 3vw;
              border-top: 1px solid #e2e2e2;
            "
          >
            <div
              v-if="index < table_data4.length - 1"
              style="
                min-width: 6vw;
                text-align: center;
                border-left: 1px solid #e2e2e2;
                min-height: 3vh;
                line-height: 3vh;
              "
            >
              {{ index + 1 }}
            </div>
            <div
              v-if="index == table_data4.length - 1"
              style="
                min-width: 6vw;
                text-align: center;
                border-left: 1px solid #e2e2e2;
                min-height: 3vh;
                line-height: 3vh;
              "
            ></div>
            <div
              style="
                width: 30vw;
                text-align: center;
                border-left: 1px solid #e2e2e2;
                min-height: 3vh;
                line-height: 3vh;
              "
            >
              {{ item.门店名称 }}
            </div>
            <el-tooltip :content="item.管易店铺名称" placement="top">
              <div
           
              style="
             
             width:35vw;
                text-align: center;
                border-left: 1px solid #e2e2e2;
                min-height: 3vh;
                line-height: 3vh;
                overflow: hidden; white-space: nowrap;
              "
            >
              {{ item.管易店铺名称}}
            </div>
</el-tooltip>
          
            <div
              style="
                width: 30vw;
                text-align: center;
                border-left: 1px solid #e2e2e2;
                min-height: 3vh;
                line-height: 3vh;
              "
            >
              {{ item.销售额  }}
            </div>
            <div
              style="
                width: 30vw;
                text-align: center;
                border-left: 1px solid #e2e2e2;
                min-height: 3vh;
                line-height: 3vh;
              "
            >
              {{ item.成本额 }}
            </div>
            <div
              style="
                width: 30vw;
                text-align: center;
                border-left: 1px solid #e2e2e2;
                min-height: 3vh;
                line-height: 3vh;
              "
            >
              {{ item.毛利额 }}
            </div>
            <div
              style="
                width: 30vw;
                text-align: center;
                border-left: 1px solid #e2e2e2;
                min-height: 3vh;
                line-height: 3vh;
              "
            >
              {{ item.成本价 }}
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!--底部tab栏-->
    <Tabbar
      :choose_index="tab_type"
      :type="pagetype"
      :user_type2="user_type2"
    ></Tabbar>
  </div>
</template>

<script>
import LineChart from "@/components/LineChart";
import Tabbar from "@/components/Tabbar";
export default {
  components: {
    LineChart,
    Tabbar,
  },
  data() {
    return {
      show_tab: true,
      tableData3: [
        {
          date: "2016-05-03",
          name: "王小虎",
          province: "上海",
          city: "普陀区",
          address: "上海市普陀区金沙江路 1518 弄",
          zip: 200333,
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          province: "上海",
          city: "普陀区",
          address: "上海市普陀区金沙江路 1518 弄",
          zip: 200333,
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          province: "上海",
          city: "普陀区",
          address: "上海市普陀区金沙江路 1518 弄",
          zip: 200333,
        },
      ],
      time_item: [
        {
          name: "今日",
          icon: require("@/assets/icon/shishi.png"),
          icon_xuan: require("@/assets/icon/shishi_xuan.png"),
          value: 0,
        },
        {
          name: "昨日",
          icon: require("@/assets/icon/zuori.png"),
          icon_xuan: require("@/assets/icon/zuori_xuan.png"),
          value: 1,
        },
        {
          name: "一周",
          icon: require("@/assets/icon/yizhou.png"),
          icon_xuan: require("@/assets/icon/yizhou_xuan.png"),
          value: 2,
        },
        {
          name: "当月",
          icon: require("@/assets/icon/dangyue.png"),
          icon_xuan: require("@/assets/icon/dangyue_xuan.png"),
          value: 3,
        },
      ], //时间队列
      pagetype: 0, //1 为总部看板 2为门店看板
      tab_type: 0, //tab栏
      startTime: "", //开始时间
      endTime: "", //结束时间
      table_data1: [], //数据体1
      table_data2: [], //数据体2
      table_data3: [], //数据体3
      table_data4: [], //数据体4 电商排名
      line_data: [], //折现统计图
      time_value: 0, //默认显示实时
      img_list: [
        require("@/assets/icon/hanshui.png"),
        require("@/assets/icon/xin.png"),
        require("@/assets/icon/hanshuixiao.png"),
        require("@/assets/icon/hanshuixiao.png"),
        require("@/assets/icon/hanshuixiao.png"),
        require("@/assets/icon/hanshuixiao.png"),
        require("@/assets/icon/keliu.png"),
        require("@/assets/icon/huiyuan.png"),
        require("@/assets/icon/yingye.png"),
        require("@/assets/icon/hanshuixiao.png"),
        require("@/assets/icon/hanshuimao.png"),
        require("@/assets/icon/keliuliang.png"),
        require("@/assets/icon/kedanjia.png"),
        require("@/assets/icon/zongmaoli.png"),
        require("@/assets/icon/chengben.png"),
        require("@/assets/icon/chengben.png"),
        require("@/assets/icon/chengben.png"),
      ],
      menu_list1: [], //菜单1
      esampleLisdt:[],
      // menu_list2:[],//菜单2
      loading: "", //loading
      new_show_date: "", //日期
      user_type2: false, //区域账号
      type2shopId: "", //区域id
      eight: "",
     binYList:[],

      shList: [
        {
          name1: "李某某",
          time: 2023.7,
          sf: 4511,
          carSf: 2000,
          jcSf: 600,
          heji: 6100,
        },
        {
          name1: "冯某某",
          time: 2023.7,
          sf: 4500,
          carSf: 1000,
          jcSf: 600,
          heji: 6100,
        },
      ],
      filters: [
        { name: "先生", a1: 800, a2: 0, a3: 800, a4: 0 },
        { name: "跑堂", a1: 800, a2: 0, a3: 800, a4: 0 },
        { name: "整理遗体", a1: 800, a2: 0, a3: 800, a4: 0 },
        { name: "餐厅", a1: 800, a2: 0, a3: 800, a4: 0 },
        { name: "住宿", a1: 800, a2: 0, a3: 800, a4: 0 },
        { name: "会客厅", a1: 800, a2: 0, a3: 800, a4: 0 },
        { name: "丧葬用品", a1: 800, a2: 0, a3: 800, a4: 0 },
        { name: "接尸", a1: 0, a2: 500, a3: 0, a4: 500 },
        { name: "出殡", a1: 0, a2: 500, a3: 0, a4: 500 },
      ],
    };
  },
  watch: {
    // $route(){
    //     this.pagetype = this.$route.query.pagetype*1;//切换总部看板 与 门店看板
    //     this.curve_api();
    //     this.viewmenu_api();
    // }
  },
  mounted() {
   
    this.$axios.post(this.$api.user_api).then((res) => {
      console.log(res, "用户信息");

      // localStorage.setItem("user_info", JSON.stringify(res.data.data));

      this.userType = res.data.data.type;
      if (this.userType == 0) {
        this.pagetype = 1;
      } else if (this.userType == 2) {
        this.user_type2 = true;
      } else {
        this.pagetype = 2;
      }
      this.shopId = res.data.data.shopId;
    });
    // this.eight = JSON.parse(localStorage.getItem("user_info")).type;
    // console.log(this.eight, "aaa");
    if (localStorage.getItem("user_info") != "") {
      this.tab_type = this.$route.query.pagetype * 1;
      // console.log(this.tab_type, "o900909090-0-9");
      // this.pagetype = this.$route.query.pagetype*1;//切换总部看板 与 门店看板
      if (
        JSON.parse(localStorage.getItem("user_info")).type == 0 ||
        JSON.parse(localStorage.getItem("user_info")).type == 8
      ) {
        //总部看板
        this.pagetype = 1;
      } else {
        //门店看板
        this.pagetype = 2;
      }
      //区域看板
      if (
        JSON.parse(localStorage.getItem("user_info")).type == 2 ||
        JSON.parse(localStorage.getItem("user_info")).type == 4 ||
        JSON.parse(localStorage.getItem("user_info")).type == 6
      ) {
        //2为区域账号
        this.user_type2 = true;
        this.getShopByArea();
      } else {
        console.log("111111111");
        this.init();
      }
    }
  },
  methods: {
    //初始化
    // 跳转到值班收费明细
    table_on(val) {
      console.log("值班收费明细", val);
      this.$router.push(`./class?name=${val.商户}&time=${val.入殡时间}`);
    },
    //跳转到车队收费明细
    car_on(val) {
      console.log("车队收费明细", val);
      this.$router.push(`./car?name=${val.商户}&time=${val.入殡时间}`);
    },
    init() {
      this.getShowDate(); //格式化日期
      // this.showLoading();
      this.endTime = this.getYYYYMMDD();
      this.startTime = this.getYYYYMMDD();
      this.time_value = 0;
      // this.curve_api();
      this.viewmenu_api();
      this.feeRegistration()
      this.getDataComparison()
      // if (this.pagetype == 1) {
      //   //总部看板获取电商
      //   this.curve2_api();
      // }
    },
    feeRegistration(){
      this.$axios
        .post(this.$api.feeRegistration, {
          endTime:this.endTime,
          startTime:this.startTime,
          userType:10
        })
        .then((res) => {
          console.log(res.data.data,"")
          this.binYList=res.data.data
          // this.type2shopId = res.data.data.shopId;
          // this.init();
        });
    },
    getDataComparison(){
      this.$axios
        .post(this.$api.getDataComparison, {
          endTime:this.endTime,
          startTime:this.startTime,
          userType:10
        })
        .then((res) => {
          console.log(res.data.data,"")
          this.esampleLisdt=res.data.data
          let sum1 = 0;
    let sum2 = 0;
    let sum3 = 0;
    let sum4 = 0;
    this.esampleLisdt.forEach((ele) => {
      sum1 = sum1 + ele.singleNonFleetFee;
      sum2 = sum2 + ele.singleFleetFee;
      sum3 = sum3 + ele.doubleNonFleetFee;
      sum4 = sum4 + ele.doubleFleetFee;
    });
    this.esampleLisdt.push({
      typeName: "合计",
      singleNonFleetFee: sum1,
      singleFleetFee: sum2,
      doubleNonFleetFee: sum3,
      doubleFleetFee: sum4,
    });
          // this.type2shopId = res.data.data.shopId;
          // this.init();
        });
    },
    //获取区域详情
    getShopByArea() {
      this.$axios
        .post(this.$api.getShopByArea, {
          area: JSON.parse(localStorage.getItem("user_info")).shopId,
        })
        .then((res) => {
          this.type2shopId = res.data.data.shopId;
          this.init();
        });
    },
    //获取显示日期
    getShowDate() {
      this.new_show_date = this.getYYYYMMDD();
      // let new_date = new Date(Date.now());
      // let new_tomorrow = new Date(Date.now() - 86400000);
      // this.time_item[0].name = new_date.getDate() + '号';
      // this.time_item[1].name = new_tomorrow.getDate() + '号';
    },
    //loading
    showLoading() {
      this.loading = this.$loading({
        lock: true,
        text: "查询中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    },
    //时间格式化
    getYYYYMMDD(e) {
      let nowDate = "";
      if (e) {
        nowDate = new Date(e);
      } else {
        nowDate = new Date();
      }
      let day = nowDate.getDate();
      let month = nowDate.getMonth() + 1;
      let year = nowDate.getFullYear();
      return year + "-" + month + "-" + day;
    },
    //选择时间
    chooseTime(e) {
      // this.showLoading();
      this.time_value = e;
      if (e == 0) {
        //实时
        this.startTime = this.getYYYYMMDD();
        this.endTime = this.getYYYYMMDD();
      } else if (e == 1) {
        //昨日
        this.startTime = this.getYYYYMMDD(Date.now() - 86400000);
        this.endTime = this.getYYYYMMDD(Date.now() - 86400000);
      } else if (e == 2) {
        //一周
        this.startTime = this.getYYYYMMDD(Date.now() - 518400000);
        this.endTime = this.getYYYYMMDD();
      } else if (e == 3) {
        //当月
        let nowDate = new Date();
        let month = nowDate.getMonth() + 1;
        let year = nowDate.getFullYear();
        this.startTime = year + "-" + month + "-1";
        this.endTime = this.getYYYYMMDD();
      }
      // this.curve_api();
      // this.curve2_api();
      this.viewmenu_api();
      this.feeRegistration()
      this.getDataComparison()
    },
    //获取电商排名
    curve2_api() {
      this.$axios
        .post(this.$api.curve2_api, {
          shopId: this.user_type2
            ? this.type2shopId
            : JSON.parse(localStorage.getItem("user_info")).userId,
          startTime: this.startTime,
          endTime: this.endTime,
          userType: JSON.parse(localStorage.getItem("user_info")).type,
        })
        .then((res) => {
          // console.log( res.data.data[1], "1111111111");
          if (res.data.data != "") {
            this.table_data4 = [];
            if (res.data.data[1] == undefined || res.data.data[1] == "") {
              res.data.data[1] = [];
            }
            if (res.data.data[0] == undefined || res.data.data[0] == "") {
              res.data.data[0] = [];
            }
            this.table_data4 = this.table_data4.concat(
              res.data.data[0],
              res.data.data[1]
            );
            // console.log(res, "电商");
            //对table_data4进行排序
            for (let i = 0; i < this.table_data4.length; i++) {
              for (let j = 0; j < this.table_data4.length; j++) {
                // console.log(this.table_data4.length[j])
                // console.log(this.table_data4.length[j + 1])
                if (
                  this.table_data4.length[j] < this.table_data4.length[j + 1]
                ) {
                  let tmp = this.table_data4.length[j];
                  this.table_data4.length[j] = this.table_data4.length[j + 1];
                  this.table_data4.length[j + 1] = tmp;
                }
              }
            }
            //计算总价
            let total = {
              门店名称: "合计",
              销售额: 0,
              成本额: 0,
              毛利额: 0,
              成本价: 0,
            };
            for (let i = 0; i < this.table_data4.length; i++) {
              total.销售额 = total.销售额 + this.table_data4[i].销售额 * 1;
              total.成本额 = total.成本额 + this.table_data4[i].成本额 * 1;
              total.毛利额 = total.毛利额 + this.table_data4[i].毛利额 * 1;
              total.成本价 = total.成本价 + this.table_data4[i].成本价 * 1;
            }
            total.销售额 = total.销售额.toFixed(2) * 1;
            total.成本额 = total.成本额.toFixed(2) * 1;
            total.毛利额 = total.毛利额.toFixed(2) * 1;
            total.成本价 = total.成本价.toFixed(2) * 1;
            this.table_data4.push(total);
          }
        });
    },
    //获取曲线及销售排名数据
    curve_api() {
      let url = this.$api.curve_api;
      let params = {
        userId: JSON.parse(localStorage.getItem("user_info")).userId,
        userType: JSON.parse(localStorage.getItem("user_info")).type,
        startTime: this.startTime,
        endTime: this.endTime,
      };
      if (
        JSON.parse(localStorage.getItem("user_info")).type != 0 &&
        JSON.parse(localStorage.getItem("user_info")).type != 8
      ) {
        //0为总部看板
        url = this.$api.curve_api + "1";
        if (this.user_type2) {
          //区域id
          params.shopId = this.type2shopId;
        } else {
          params.shopId = JSON.parse(localStorage.getItem("user_info")).shopId;
        }
      }

      this.$axios.post(url, params).then((res) => {
        // console.log(res, "a");
        this.loading.close();
        console.log(this.table_data3, "销售额");
        this.table_data3 = res.data.data[0];
        //计算合计数据
        if (this.pagetype == 1) {
          //1总店看板
          let total = {
            销售额: 0,
            毛利额: 0,
            毛利率: 0,
            gk毛利: 0,
            gk毛利率: 0,
            分支机构: "合计",
            分支机构编码: "合计",
          };
          // console.log(this.table_data3, "3");
          if (this.table_data3 != null) {
            for (let i = 0; i < this.table_data3.length; i++) {
              this.table_data3[i].分支机构编码 = this.table_data3[i].分支机构;
              //去掉前五位
              // console.log(this.table_data3[i].分支机构,"分支机构")
              this.table_data3[i].分支机构 = this.table_data3[
                i
              ].分支机构.substring(6, this.table_data3[i].分支机构.length);
              // console.log(this.table_data3[i].分支机构,"截取")
              total.销售额 = total.销售额 * 1 + this.table_data3[i].销售额 * 1;
              total.毛利额 = (
                total.毛利额 * 1 +
                this.table_data3[i].毛利额 * 1
              ).toFixed(1);
              total.毛利率 = (
                total.毛利率 * 1 +
                this.table_data3[i].毛利率 * 1
              ).toFixed(1);
              total.gk毛利 = (
                total.gk毛利 * 1 +
                this.table_data3[i].gk毛利 * 1
              ).toFixed(1);
              total.gk毛利率 = (
                total.gk毛利率 * 1 +
                this.table_data3[i].gk毛利率 * 1
              ).toFixed(1);
            }
          }

          // console.log(this.table_data3, "33333");
          total.销售额 = total.销售额.toFixed(1);
          this.line_data = this.table_data3; //折线图
          console.log(total, "平均");
          console.log(total.毛利率, "毛利率");
          console.log(total.gk毛利, "gk毛利");
          console.log(total.销售额, "销售额");
          console.log(total.毛利率 / total.销售额, "折线");

          total.毛利率 = ((total.毛利额 / total.销售额) * 100).toFixed(2) + "%";
          // total.毛利率=(total.毛利率*100)+"%"
          total.gk毛利率 =
            ((total.gk毛利 / total.销售额) * 100).toFixed(2) + "%";
          // total.gk毛利率=(total.gk毛利率*100)+"%"
          if (this.table_data3 != null) {
            this.table_data3.push(total); //带上总计
          }

          console.log(this.table_data3, "合计");
        } else {
          //2门店看板
          let total = {
            销售额: 0,
            营业员: "合计",
            会员销售额: 0,
            客单价: 0,
            客流量: 0,
          };
          // console.log(this.table_data3, "4");

          if (this.table_data3 != null) {
            for (let i = 0; i < this.table_data3.length; i++) {
              this.table_data3[i].营业员 = this.table_data3[i].营业员;
              total.销售额 = total.销售额 * 1 + this.table_data3[i].销售额 * 1;
              total.会员销售额 =
                total.会员销售额 * 1 + this.table_data3[i].会员销售额 * 1;
              total.客单价 = total.客单价 * 1 + this.table_data3[i].客单价 * 1;
              total.客流量 = total.客流量 * 1 + this.table_data3[i].客流量 * 1;
            }
          }
          total.销售额 = total.销售额.toFixed(2) * 1;
          total.会员销售额 = total.会员销售额.toFixed(2) * 1;
          total.客单价 = total.客单价.toFixed(2) * 1;
          total.客流量 = total.客流量.toFixed(2) * 1;
          this.line_data = this.table_data3; //折线图
          // console.log(this.line_data, "折线111");
          if (this.table_data3 != null) {
            this.table_data3.push(total); //带上总计
          }
        }
      });
    },
    //获取看板名字
    viewmenu_api() {
      this.$axios
        .post(this.$api.viewmenu_api, {
          userType: JSON.parse(localStorage.getItem("user_info")).type,
        })
        .then((res) => {
          //格式化菜单
          let menu_data = res.data.data;
          this.menu_list1 = [];
          // this.menu_list2 = [];
          let every_item = {};
          // for(let i=0;i<4;i++){
          //   every_item = {
          //     name:menu_data[i].view,
          //     sql: menu_data[i].sql
          //   }
          //   this.menu_list1.push(every_item)
          // }
          // for(let i=0;i<5;i++){
          //   every_item = {
          //     name:menu_data[i].view,
          //     sql: menu_data[i].sql
          //   }
          //   this.menu_list2.push(every_item)
          // }
          for (let i = 0; i < menu_data.length; i++) {
            every_item = {
              name: menu_data[i].view,
              sql: menu_data[i].sql,
            };
            this.menu_list1.push(every_item);
          }
          this.view1_api();
        });
    },
    //获取看板数据
    view1_api() {
      this.table_data1 = [];
      this.table_data2 = [];
      // console.log(this.menu_list1,"11")
      for (let i = 0; i < this.menu_list1.length; i++) {
        if (this.menu_list1[i].name == "毛利率") {
          this.$delete(this.menu_list1, i);
        }
        console.log(this.menu_list1[i], "this.menu_list1");
        let query = {
          userId: JSON.parse(localStorage.getItem("user_info")).userId,
          userType: JSON.parse(localStorage.getItem("user_info")).type,
          shopId: this.user_type2
            ? this.type2shopId
            : JSON.parse(localStorage.getItem("user_info")).shopId,
          startTime: this.startTime,
          endTime: this.endTime,
          sql: this.menu_list1[i].sql,
        };
        this.$axios.post(this.$api.view1_api + (i + 1), query).then((res) => {
          // console.log(res.data.data, "every_data");
          let every_data = res.data.data;
          if (!every_data.length) {
            // console.log("g");
            return false;
          }
          if (this.pagetype == 1) {
            //总部两个数据源合并
            if (every_data[0] && every_data[1]) {
              //若两个都存在的情况
              // console.log("A");
              let every_name = this.menu_list1[i].name;
              every_data[0][every_name] =
                every_data[0][every_name] + every_data[1][every_name];
            } else if (every_data[1]) {
              // console.log("B");
              every_data[0][every_name] = every_data[1][every_name];
            }
          }

          for (let k = 0; k < this.menu_list1.length; k++) {
            //  console.log(every_data[0],"every_data[0]")
            if (every_data[0] != null) {
              if (this.menu_list1[k].name == Object.keys(every_data[0])[0]) {
                let every_item = {
                  name: Object.keys(every_data[0])[0],
                  value: Object.values(every_data[0])[0],
                  item_index: k,
                  img: this.img_list[k],
                };

                if (k < 2) {
                  this.table_data1[k] = every_item;
                } else {
                  this.table_data2[k - 2] = every_item;
                  // console.log(every_item)
                }
                this.table_data1.push(",");
                this.table_data1.pop();
                // console.log(this.table_data1,"this.table_data1")
              }
            } else {
              // console.log(this.menu_list1[k].name == this.menu_list1[i].name,"this.menu_list1[k].name == this.menu_list1[i].name")
              if (this.menu_list1[k].name == this.menu_list1[i].name) {
                let every_item = {
                  name: this.menu_list1[k].name,
                  value: 0,
                  item_index: k,
                  img: this.img_list[k],
                };

                if (k < 2) {
                  this.table_data1[k] = every_item;
                } else {
                  this.table_data2[k - 2] = every_item;
                }
                this.table_data1.push(",");
                this.table_data1.pop();
              }
            }
          }
        });
      }
    },
  },
};
</script>
<style scoped>
table {
  text-align: center;
}
/* td {
  display: flex;
  justify-content: center;
  align-items: center;
} */
/* .container {
  display: flex;
  align-items: center;
} */
table {
  margin: 0 auto;
}
.title_div {
  height: 9vh;
  background-image: linear-gradient(#04a9f3, #4ec5fb);
  color: #ffffff;
  /* line-height:12vh;
  text-align: center;
  font-size:4.6vw; */
}
.every_option1 {
  height: 9vh;
  width: 45.6vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  position: relative;
  border-radius: 9px;
}
.every_time {
  height: 5vh;
  width: 23vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.option_img {
  height: 5vw;
  width: 5vw;
  margin-right: 1.5vw;
}
.option_title {
  font-size: 4vw;
  font-weight: 800;
  color: #000000;
}
.every_option2 {
  /* width: 30vw;

  background-color: #ffffff;
  margin-top: 0.8vh; */
  border-radius: 9px;
  height: 9vh;
  width: 44.7vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  position: relative;
  margin-top: 1.5vw;
}
.every_optionsF {
  width: 91.5vw;

  background-color: #ffffff;
  margin-top: 0.8vh;
  border-radius: 10px;
}
.option2_font {
  height: 4vh;
  line-height: 5vh;
  /* font-size:1vw; */
  transform: scale(0.8);
  width: 34vw;
  margin-left: -2vw;
  color: #898989;
}
.option2_font2 {
  font-size: 3vw;
  color: #898989;
  margin-left: 1vw;
}
.ranking_icon {
  height: 3.6vw;
  width: 4vw;
}
.time_choose {
  height: 5.4vw;
  line-height: 5.4vw;
  font-size: 3.6vw;
  margin-left: 1.6vw;
}
.line_body {
  height: 23vh;
  background-color: #ffffff;
  overflow: scroll;
}
.el-table thead.is-group th {
  background: #fff;
}

.el-table thead.is-group tr:first-of-type th:first-of-type:before {
  content: "1";
  text-align: center;
  position: absolute;
  width: 152px;
  height: 1px;
  bottom: 30px;
  right: 0;
}

.el-table thead.is-group tr:first-of-type th:first-of-type:after {
  content: "2";
  text-align: center;
  position: absolute;
  width: 152px;
  top: 10px;
  left: 0;
}

.el-table thead.is-group tr:first-of-type th:first-of-type .cell {
  position: absolute;
  top: 0;
  left: 0;
  width: 152px;
  height: 1px;
  background-color: #7891c9;
  display: block;
  text-align: center;
  transform: rotate(38deg);
  transform-origin: top left;
  -ms-transform: rotate(38deg);
  -ms-transform-origin: top left;
  -webkit-transform: rotate(38deg);
  -webkit-transform-origin: top left;
}
.dic{
  border-bottom:1px solid #999;
  transform: rotate(25deg);
}

</style>
